import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BasicTemplate } from '@templates/BasicTemplate';
import Hero from '@components/molecules/Hero/Hero';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import Button from '@components/atoms/Button/Button';
import IconBlock from '@components/atoms/IconBlock/IconBlock';
import Gallery from '@components/organisms/Gallery/Gallery';
import { graphql } from 'gatsby';
// import InteractiveVisualization from '@components/molecules/InteractiveVisualization/InteractiveVisualization';
import Map from '@components/molecules/Map/Map';
import FinishingStandard from '@components/molecules/FinishingStandard/FinishingStandard';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import OurInvestments from '@components/molecules/OurInvestments/OurInvestments';
import { scrollTo } from '@utils/scrollTo';
import Context from '@context';
import { firstText, secondText } from '@config/indexPage';
import Contact from '@components/organisms/Contact/Contact';
import { useLocation } from 'react-use';
import RendProInteractiveVis from '@components/molecules/RendProInteractiveVis/RendProInteractiveVis';
import NewMap from '../components/molecules/NewMap/NewMap';

const StyledSection = styled.section`
  width: 90%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;

  @media (min-width: 470px) {
    margin-top: 85px;
  }

  @media (min-width: 1025px) {
    max-width: 1250px;
    margin-top: 180px;
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 45px;

  @media (min-width: 520px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1025px) {
    margin-bottom: 120px;
  }
`;

const StyledButton = styled(Button)`
  margin: 45px auto;

  @media (min-width: 1025px) {
    margin: 80px auto;
  }
`;

const IconBlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 45px 0 35px 0;

  @media (min-width: 1025px) {
    margin-bottom: 150px;
  }
`;

const StyledIconBlock = styled(IconBlock)`
  width: 40%;
  margin-bottom: 10px;

  @media (min-width: 1025px) {
    width: 30%;
    margin-bottom: 40px;
  }
`;

const StyledMap = styled(Map)`
  margin: 25px 0 85px;

  :last-of-type {
    margin: 25px 0 0;
  }

  @media (min-width: 1025px) {
    margin-bottom: 240px;
  }
`;

const StyledFinisingStandardsWrapper = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 170px;
    margin-bottom: -60px;

    .last_finishingStandards_box {
      transform: translateX(-50%);
    }
  }
`;

const StyledFinishindStandard = styled(FinishingStandard)`
  margin-bottom: 25px;

  :last-of-type {
    margin-bottom: 65px;
  }

  @media (min-width: 1025px) {
    width: 45%;
    margin-bottom: 60px;
  }
`;

const StyledLogo = styled.img`
  width: 70%;
  margin: 45px auto;
  display: block;

  @media (min-width: 1025px) {
    margin: 45px 0;
  }
`;

const StyledFigure = styled.figure`
  margin: 40px 0 65px 0;

  figcaption {
    text-align: center;
    font-size: ${({ theme }) => theme.bodyXXS};
    margin-top: 10px;

    span {
      display: block;
    }
  }

  @media (min-width: 1025px) {
    width: 80%;
    margin: 140px auto;

    figcaption {
      font-size: ${({ theme }) => theme.bodyM};
      margin-top: 20px;

      span {
        display: inline;
      }
    }
  }
`;

const StyledParagraphsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${Paragraph} {
    width: 40%;
  }
`;

const StyledParagraph = styled(Paragraph)`
  @media (min-width: 1025px) {
    max-width: 776px;
    margin: 200px auto 80px;
  }
`;

const StyledAboutUsWrapper = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledAboutUsColumn = styled.div`
  @media (min-width: 1025px) {
    width: 40%;

    :last-of-type {
      margin-top: 70px;
    }
  }
`;

const StyledAboutUsImg = styled(GatsbyImage)``;

const Index: FC<Props> = ({ data, ...props }) => {
  const [width, setWidth] = useState<number>(null);
  const handleButtonClick = () => scrollTo('#contact');
  const { state } = useLocation();

  const galleryImages = data.gallery.nodes;
  let galleryFixedImages = data.galleryFixed.nodes.map(
    ({
      childImageSharp: {
        fixed: { src },
      },
    }) => src
  );

  useEffect(() => {
    if (state?.prevPath?.includes('dom')) {
      scrollTo('#offer');
    }
  }, [state?.prevPath]);

  return (
    <BasicTemplate header={Hero} setWidth={setWidth} title="Rząska" {...props}>
      <StyledSection>
        <StyledHeadline
          behindText="O osiedlu"
          id="about_estate"
          secondaryText="O osiedlu"
        >
          Nowoczesne mieszkania o podwyższonym standardzie
        </StyledHeadline>

        {width >= 1025 ? (
          <>
            <StyledParagraphsWrapper>
              <Paragraph
                dangerouslySetInnerHTML={{ __html: firstText }}
                data-aos="fade-up"
              />
              <Paragraph
                dangerouslySetInnerHTML={{ __html: secondText }}
                data-aos="fade-up"
                data-aos-delay="500"
              />
            </StyledParagraphsWrapper>

            <StyledButton icon="phone" onClick={handleButtonClick}>
              Skontaktuj się
            </StyledButton>
          </>
        ) : (
          <>
            <Paragraph
              dangerouslySetInnerHTML={{ __html: firstText }}
              data-aos="fade-up"
            />

            <StyledButton icon="phone" onClick={handleButtonClick}>
              Skontaktuj się
            </StyledButton>

            <Paragraph
              dangerouslySetInnerHTML={{ __html: secondText }}
              data-aos="fade-up"
            />
          </>
        )}

        <IconBlocksWrapper>
          <StyledIconBlock
            icon="verified"
            data-aos="fade-down-left"
            data-aos-duration="500"
          >
            Zielona, spokojna okolica
          </StyledIconBlock>
          <StyledIconBlock
            icon="location"
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="500"
          >
            11 km do rynku
          </StyledIconBlock>
          <StyledIconBlock
            icon="train"
            data-aos="fade-down-right"
            data-aos-delay="600"
            data-aos-duration="500"
          >
            Kolej do centrum Krakowa
          </StyledIconBlock>
          <StyledIconBlock
            icon="university"
            data-aos="fade-down-left"
            data-aos-duration="500"
          >
            W pobliżu szkoła, plac zabaw, orlik
          </StyledIconBlock>
          <StyledIconBlock
            icon="basket"
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="500"
          >
            Nieopodal Galeria Handlowa Factory
          </StyledIconBlock>
          <StyledIconBlock
            icon="park"
            data-aos="fade-down-right"
            data-aos-delay="600"
            data-aos-duration="500"
          >
            Atrakcyjne tereny do spacerów i jazdy na rowerze
          </StyledIconBlock>
        </IconBlocksWrapper>
      </StyledSection>

      <Gallery images={galleryImages} fixedImages={galleryFixedImages} />

      <StyledSection style={{ width: '100%', maxWidth: 'unset' }}>
        <StyledHeadline
          behindText="Oferta"
          behindTextSize="11rem"
          id="offer"
          secondaryText="Oferta mieszkań"
        >
          Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty
        </StyledHeadline>
        {/* <InteractiveVisualization data-aos="fade-up" /> */}
        <RendProInteractiveVis />
      </StyledSection>

      <StyledSection>
        <StyledHeadline
          behindText="Lokalizacja"
          behindTextSize="6.2rem"
          id="location"
          secondaryText="Lokalizacja"
        >
          Nowe osiedle w świetnej lokalizacji
        </StyledHeadline>

        <StyledParagraph data-aos="fade-up">
          Osiedle Diamentowe Zacisze zlokalizowane jest w miejscowości Rząska
          przy ul. Półkole. Pełna dostępność komunikacji publicznej oraz
          bliskość Autostardy A4 sprawia, że bez trudu dostaniemy się do
          wybranego celu. Dużym atutem jest dojazd do osiedla nową drogą
          asfaltową.
        </StyledParagraph>

        {/* <StyledMap data-aos="fade-up" /> */}
        <NewMap />
      </StyledSection>

      <StyledSection>
        <StyledHeadline
          behindText="Standard"
          behindTextSize="7.9rem"
          id="finishing_standard"
          secondaryText="Standard"
        >
          Standard wykończenia
        </StyledHeadline>

        <StyledFinisingStandardsWrapper>
          <StyledFinishindStandard
            icon="/foundation.svg"
            headline="Fundamenty"
            data-aos="flip-up"
            data-aos-delay="200"
            data-aos-anchor="#finishing_standard"
          >
            Ławy i ściany fundamentowe żelbetonowe
          </StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/outer_wall.svg"
            headline="Ściany zewnętrzne"
            data-aos="flip-up"
            data-aos-delay="400"
            data-aos-anchor="#finishing_standard"
          >
            Pustak Winerberger Porotherm „25”
          </StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/interior_wall.svg"
            headline="Ściany wewnętrzne"
            data-aos="flip-up"
            data-aos-anchor="#finishing_standard"
            data-aos-delay="600"
          >
            Pustak Winerberger Porotherm „11,5” + Silikat. Odporność ogniowa i
            akustyka 50dB
          </StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/wiring.svg"
            headline="Instalacja elektryczna"
            data-aos="flip-up"
            data-aos-anchor="#finishing_standard"
            data-aos-delay="800"
          >
            Możliwość wprowadzenia zmian pod klienta
          </StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/driveway.svg"
            headline="Podjazdy i miejsca postojowe"
            data-aos="flip-up"
            data-aos-anchor="#finishing_standard"
            data-aos-delay="1000"
          >
            Podjazd i miejsce postojowe wykonany z kostki brukowej
          </StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/roofing.svg"
            headline="Pokrycie dachowe"
            data-aos="flip-up"
            data-aos-anchor="#finishing_standard"
            data-aos-delay="1200"
          >
            Dachówka CREATON SIMPLA czarny mat angoba Blacha na rąļek BRATEX
            SNAP LOCK
          </StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/glass-window.svg"
            headline="Wysokiej jakości energooszczędne okna"
            data-aos="flip-up"
            data-aos-anchor="#finishing_standard"
            data-aos-delay="1400"
          ></StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/heating.svg"
            headline="Ogrzewanie podłogowe w całym domu"
            data-aos="flip-up"
            data-aos-anchor="#finishing_standard"
            data-aos-delay="1600"
          >
            Piec gazowy i rekuperacja
          </StyledFinishindStandard>

          <StyledFinishindStandard
            icon="/fence.svg"
            headline="Ogrodzone ogródki na parterze"
            data-aos="flip-up"
            data-aos-delay="1800"
            data-aos-anchor="#finishing_standard"
            className="last_finishingStandards_box"
          ></StyledFinishindStandard>
        </StyledFinisingStandardsWrapper>
      </StyledSection>

      <StyledSection>
        <StyledHeadline
          behindText="O nas"
          behindTextSize="12.2rem"
          id="about_us"
          secondaryText="O nas"
        >
          Rodzinna firma z pasją do nieruchomości
        </StyledHeadline>

        <StyledAboutUsWrapper>
          <StyledAboutUsColumn>
            <Paragraph data-aos="fade-up-right">
              Marys sp. z o.o. jest rodzinną firmą, działającą na rynku
              nieruchomości od 2010r. Od wielu lat jesteśmy związani z branżą
              budowlaną oraz wnętrzarską, w swoim portfelu posiadamy również
              nieruchomości na wynajem.
            </Paragraph>

            {width >= 1025 && (
              <StyledLogo
                src="/maryslogo.svg"
                alt="Marys nieruchomości"
                data-aos="fade-up-right"
              />
            )}
          </StyledAboutUsColumn>

          <StyledAboutUsColumn>
            <Paragraph data-aos="fade-up-left">
              Dzięki naszemu doświadczeniu z powodzeniem tworzymy kolejne
              indywidualne projekty deweloperskie, mieszkania pod klucz dla
              rodzin oraz produkty inwestycyjne przygotowane do wynajmu krótko
              lub długoterminowego. Swoje inwestycje prowadzimy głównie na
              terenie Polski południowej.
            </Paragraph>

            {width <= 1024 && (
              <StyledLogo
                src="/maryslogo.svg"
                alt="Marys nieruchomości"
                data-aos="fade-up"
              />
            )}

            <Paragraph data-aos="fade-up-left">
              Nad powodzeniem realizowanych projektów czuwa zespół złożony z
              architektów, prawników, notariuszy i wykonawców, który jest z nami
              przy każdym przedsięwzięciu.
            </Paragraph>
          </StyledAboutUsColumn>
        </StyledAboutUsWrapper>

        <StyledFigure data-aos="fade-up">
          <StyledAboutUsImg
            image={getImage(data.aboutUs as any)}
            alt="Zarząd firmy Marys sp. z o.o.- Laura Krasowska-Dudek, Grzegorz Dudek"
          />

          <figcaption>
            Zarząd firmy Marys sp. z o.o.-{' '}
            <span>Laura Krasowska-Dudek, Grzegorz Dudek</span>
          </figcaption>
        </StyledFigure>
      </StyledSection>

      <OurInvestments />

      <Contact />
    </BasicTemplate>
  );
};

interface Props {
  data: {
    gallery: {
      nodes: { childImageSharp: { gatsbyImageData: any } }[];
    };
    galleryFixed: {
      nodes: { childImageSharp: { fixed: { src: string } } }[];
    };
    aboutUs: {
      childImageSharp: { gatsbyImageData: any };
    };
  };
}

export const query = graphql`
  {
    gallery: allFile(
      filter: {
        name: { regex: "/gallery1|gallery2|gallery3|gallery4|gallery5/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [WEBP, JPG]
            layout: FULL_WIDTH
          )
        }
      }
    }

    galleryFixed: allFile(
      filter: {
        name: { regex: "/gallery1|gallery2|gallery3|gallery4|gallery5/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          fixed(quality: 80, width: 3840) {
            src
          }
        }
      }
    }

    aboutUs: file(name: { eq: "about_us" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 70
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default Index;
