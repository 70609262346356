import React, { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { useIcon } from '@hooks/useIcon';
import { Icons } from '@config/icons';
import Paragraph from '@components/atoms/Paragraph/Paragraph';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    padding: 60px 70px;
    border-radius: 35px;
    transition: box-shadow 0.2s ease-in-out;

    :hover {
      box-shadow: 0px 0px 30px #bab8bf59;
    }
  }
`;

const StyledIconWrapper = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.background};
  font-size: 3.5rem;
  
  @media (min-width: 1025px) {
    width: 145px;
    height: 145px;
    background ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    font-size: 8rem;
    transition: background .2s ease-in-out, color .2s ease-in-out;
    
    ${StyledWrapper}:hover & {
      background: ${({ theme }) => theme.primary};
      color ${({ theme }) => theme.background};
    }
  }
`;

const StyledText = styled.p`
  font-size: ${({ theme }) => theme.bodyXS};
  text-align: center;
  line-height: 1.4;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyXL};
    font-weight: ${({ theme }) => theme.medium};
    width: 220px;
    margin-top: 30px;
  }
`;

const IconBlock: FC<Props> = ({ icon, children, className, ...props }) => {
  const iconifyIcon = useIcon(icon);

  return (
    <StyledWrapper className={className} {...props}>
      <StyledIconWrapper>
        <Icon icon={iconifyIcon} />
      </StyledIconWrapper>
      <StyledText>{children}</StyledText>
    </StyledWrapper>
  );
};

interface Props {
  icon: Icons;
  className?: string;
}

export default IconBlock;
