import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIcons } from '@hooks/useIcon';
import { Icon } from '@iconify/react';
import { formatNumber } from '@utils/formatNumber';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

SwiperCore.use([Navigation]);

interface StyledProps {
  $disabled?: boolean;
}

const StyledWrapper = styled.section`
  width: 100%;
  background: #1a1a18;
  color: ${({ theme }) => theme.gray400};
  padding: 35px 0;

  .swiper-container {
    overflow: hidden;
    margin: 45px 0 25px;
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }

  @media (min-width: 1025px) {
    height: 1040px;
    padding: 100px 0;
    position: relative;

    .swiper-container {
      margin: unset;
    }

    .swiper-wrapper {
      display: flex;
      flex-direction: row;
      height: 551px;
    }
  }

  @media (min-width: 1220px) {
    .swiper-wrapper {
      height: 608px;
    }
  }

  @media (min-width: 1410px) {
    .swiper-container {
      padding-left: 100px;
      width: 85%;
    }
  }

  @media (min-width: 1545px) {
    .swiper-container {
      width: 77%;
    }
  }
`;

const StyledHeadline = styled(Headline)`
  @media (min-width: 1025px) {
    position: absolute;
    transform: rotate(-90deg) translateX(50%);
    transform-origin: top right;
    right: 10%;
    top: 50%;
    width: 80vh;
    margin: 0;
  }

  @media (min-width: 1025px) and (max-width: 1409px) {
    opacity: 0;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 85%;
  max-width: 620px;
  margin: auto;

  @media (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;
    margin: 0;
  }
`;

const StyledNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    order: -1;
    justify-content: flex-start;
    padding-left: 8%;
    margin-bottom: 100px;
  }

  @media (min-width: 1620px) {
    padding-left: 15%;
  }

  @media (min-width: 1820px) {
    padding-left: 20%;
  }
`;

const StyledNavButton = styled.button<StyledProps>`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.background};
  font-size: 4.5rem;
  padding: 10px;
  display: flex;
  outline: none;
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.gray400};
    `}
`;

const StyledCurrentIndexInfo = styled.div`
  font-size: ${({ theme }) => theme.bodyXXL};
  margin: 0 10%;

  span {
    color: ${({ theme }) => theme.background};
  }

  @media (max-width: 298px) {
    margin: 0 5%;
  }

  @media (min-width: 1025px) {
    margin: 0 50px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  cursor: pointer;

  @media (min-width: 1025px) {
    width: 908px;
    height: 551px;
  }

  @media (min-width: 1220px) {
    width: 1080px;
    height: 608px;
  }
`;

const Gallery: FC<Props> = ({ images, fixedImages }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const [leftIcon, rightIcon] = useIcons(['angle-left', 'angle-right']);

  const onSlideChange = (swiper: SwiperCore) => {
    setActiveIndex(swiper.activeIndex);
  };

  const handleClick = () => setOpen(true);

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledHeadline secondary>Galeria zdjęć</StyledHeadline>
        <Swiper
          spaceBetween={40}
          slidesPerView={1}
          speed={500}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          onSlideChange={onSlideChange}
          grabCursor
          updateOnWindowResize
          breakpoints={{
            1025: {
              slidesPerView: 'auto',
              spaceBetween: 100,
              centeredSlides: true,
            },
            1410: {
              centeredSlides: false,
              slidesPerView: 'auto',
              spaceBetween: 100,
            },
          }}
          onSwiper={setSwiperInstance}
        >
          {images.map((data, index) => (
            <SwiperSlide key={index}>
              <StyledGatsbyImage
                alt="Diamentowe Zacisze"
                image={data.childImageSharp.gatsbyImageData}
                onClick={handleClick}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <StyledNavigation>
          <StyledNavButton
            aria-label="Poprzednie zdjęcie"
            aria-disabled={activeIndex === 0}
            $disabled={activeIndex === 0}
            className="swiper-button-prev"
          >
            <Icon icon={leftIcon} />
          </StyledNavButton>
          <StyledCurrentIndexInfo>
            <span>{formatNumber(activeIndex + 1)}</span> |{' '}
            {formatNumber(images.length)}
          </StyledCurrentIndexInfo>
          <StyledNavButton
            aria-label="Następne zdjęcie"
            aria-disabled={activeIndex === images.length - 1}
            $disabled={activeIndex === images.length - 1}
            className="swiper-button-next"
          >
            <Icon icon={rightIcon} />
          </StyledNavButton>
        </StyledNavigation>
      </StyledInnerWrapper>

      {isOpen && (
        <Lightbox
          mainSrc={fixedImages[activeIndex]}
          nextSrc={fixedImages[(activeIndex + 1) % fixedImages.length]}
          prevSrc={
            fixedImages[
              (activeIndex + fixedImages.length - 1) % fixedImages.length
            ]
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            swiperInstance?.slideTo(
              (activeIndex + fixedImages.length - 1) % fixedImages.length
            )
          }
          onMoveNextRequest={() =>
            swiperInstance?.slideTo((activeIndex + 1) % fixedImages.length)
          }
        />
      )}
    </StyledWrapper>
  );
};

interface Props {
  images: any[];
  fixedImages: string[];
}

export default Gallery;
