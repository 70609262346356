import React from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import { graphql, useStaticQuery } from 'gatsby';
import Investment from '@components/molecules/OurInvestments/Investment/Investment';

const StyledWrapper = styled.section`
  width: 100%;
  background: #1a1a18;
  color: ${({ theme }) => theme.gray400};
  padding: 35px 0;
  margin-top: 35px;
  position: relative;
  overflow: hidden;

  @media (min-width: 1025px) {
    padding-top: 300px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 85%;
  max-width: 620px;
  margin: auto;
  overflow: hidden;

  @media (min-width: 1025px) {
    width: 100%;
    max-width: unset;
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 45px;

  @media (min-width: 1025px) {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: top right;
    right: 10%;
    top: 10%;
    width: 900px;
    margin: 0;
  }
`;

const OurInvestments = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(query);

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledHeadline secondary id="our_investments">
          Nasze inwestycje
        </StyledHeadline>

        <Investment
          img={nodes[0]}
          headline="DIAMENTOWE ZACISZE RZĄSKA, UL. SADOWA "
          data-aos="fade-left"
          externalUrl="https://diamentowezacisze.pl/"
        >
          Osiedle 6 jednorodzinnych domów
        </Investment>

        <Investment
          img={nodes[1]}
          headline="DIAMENTOWE ZACISZE WIĘCKOWICE "
          data-aos="fade-right"
          externalUrl="https://marysdeweloper.pl/diamentowe-zacisze-wieckowice"
        >
          Osiedle 4 domów wolnostojących
        </Investment>

        <Investment
          img={nodes[2]}
          headline="DIAMENTOWE ZACISZE RZĄSKA UL. PÓŁKOLE "
          data-aos="fade-left"
          externalUrl="https://rzaska.diamentowezacisze.pl/"
        >
          Osiedle 26 mieszkań
        </Investment>

        <Investment
          img={nodes[4]}
          headline="PRESTIŻOWY PENTHOUSE UL. BAŁUCKIEGO 29, KRAKÓW "
          data-aos="fade-left"
          externalUrl="https://marysdeweloper.pl/penthouse"
        >
          Stare Dębniki w Krakowie
        </Investment>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "otherInvestments" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [WEBP, JPG]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`;

export default OurInvestments;
