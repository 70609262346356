import React, { FC, useContext } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { coords, styles, zoom } from '@config/map';
import styled from 'styled-components';
import Context from '@context';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vw;
  max-height: 700px;

  @media (min-width: 1250px) {
    height: 60vw;
    max-height: 1175px;
  }
`;

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const style = {
  width: '100%',
  height: '100%',
};

const MapContainer: FC<Props> = ({ google, className, ...props }) => {
  const { width } = useContext(Context);

  const mapSize = width >= 1025 ? 120 : 60;

  return (
    <StyledWrapper className={className} {...props}>
      <Map
        google={google}
        initialCenter={coords}
        containerStyle={containerStyle}
        style={style}
        onReady={(_, map) => map.setOptions({ styles })}
        {...{ zoom }}
      >
        <Marker
          mapCenter={coords}
          {...{
            icon: {
              url: 'marker.svg',
              anchor: new google.maps.Point(mapSize, mapSize),
              scaledSize: new google.maps.Size(mapSize, mapSize),
            },
          }}
        />
      </Map>
    </StyledWrapper>
  );
};

interface Props {
  google: any;
  className?: string;
}

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API,
})(MapContainer);
