export const firstText = `Diamentowe Zacisze Rząska to prestiżowe osiedle 26 mieszkań w tym 13 z własnym ogrodem i 13 z balkonem. Każdy z lokali posiada również miejsca parkingowe.
Inwestycja znajduje się w samym sercu prężnie rozwijającej się miejscowości Rząska tuż za granicą Krakowa. Nowoczesny styl budynków połączony z podwyższonym standardem sprawi, 
że przekona on do siebie najbardziej wymagających klientów. .
`;

export const secondText = `
Osiedle Diamentowe Zacisze Rząska to idealne miejsce dla rodzin z dziećmi, dla których mieszkanie w ciasnej zabudowie miejskich osiedli stało się niekomfortowe i szukają własnego, wygodnego lokum.
Inwestycja powstała w zacisznym miejscu, blisko natury, równocześnie jest doskonale skomunikowana z Krakowem, autostradą A4 oraz lotniskiem w Balicach. 
Ponadto w okolicy znajdują się liczne sklepy oraz galeria handlowa.
`;
